<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
        :form="form"
      >
        <a-form-item label="标签名称">
          <a-input
            v-decorator="[
              'name',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item label="标签编码">
          <a-input
            v-decorator="[
              'code',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item label="描述">
          <a-input
            v-decorator="[
              'tagDesc',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item label="描述">
          <a-radio-group v-decorator="[
          'status',
          { rules: [{ required: true, message: '请选择！' }] },
          ]">
          <a-radio :value="1"> 正常 </a-radio>
          <a-radio :value="0"> 禁用 </a-radio>
        </a-radio-group>
        </a-form-item>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { update } from "@/api/human-resources/tag";

export default {
  name: "editTag",

  data() {
    return {
      form: this.$form.createForm(this),
      id: null
    };
  },
  activated() {
    let tagDetail = sessionStorage.getItem('tagDetail');
    if(tagDetail){
      tagDetail = JSON.parse(tagDetail);
      this.form.setFieldsValue({
        name: tagDetail.name,
        code: tagDetail.code,
        tagDesc: tagDetail.tagDesc,
        status: tagDetail.status,
      });
      this.id = tagDetail.id;
    }
    
  },
  methods: {

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          update({
            ...values,
            id: this.id
          }).then(() => {
            this.$close(this.$route.path);
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 30px 164px;
}

.center {
  margin-top: 91px;
}
</style>
